<template>
    <div class="container-fluid">
        <div class="row align-items-center justify-content-center">
            <div class="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 px-4 my-md-5 my-4">

                <!-- Logo -->
                <div class="mb-lg-5 mb-4">
                    <router-link to="/">
                        <img :src="school_info.logo" alt="Logo PPDB" style="width:50%">
                        </router-link>
                </div>

                <!-- Heading -->
                <h1 class="display-4 text-left mb-4">
                    Selamat datang!
                </h1>

                <!-- Form -->
                <form v-on:submit.prevent="handleSubmit">
                    <!-- Email address -->
                    <div class="form-group">
                        <!-- Label -->
                        <label>Email</label>
                        <!-- Input -->
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><span class="fe fe-mail"></span></span>
                            </div>
                            <input type="username" class="form-control"
                                :class="{ 'is-invalid': submitted && $v.username.$error }" v-model="username"
                                placeholder="Masukan Email">
                        </div>
                        <div v-if="submitted && !$v.username.required" class="invalid-feedback">Email
                            is required</div>
                    </div>
                    <!-- Password -->
                    <div class="form-group">
                        <div class="row">
                            <div class="col">

                                <!-- Label -->
                                <label>Kata Sandi</label>
                            </div>
                            <div class="col-auto">
                                <!-- Help text -->
                                <!-- <a href="#" class="form-text small text-muted">
													Lupa kata sandi?
												</a> -->
                            </div>
                        </div> <!-- / .row -->
                        <!-- Input group -->
                        <div class="input-group">
                             <div class="input-group-prepend">
                                <span class="input-group-text"><span class="fe fe-lock"></span></span>
                            </div>
                            <input type="password" class="form-control"
                                :class="{ 'is-invalid': submitted && $v.password.$error }" v-model="password"
                                placeholder="Masukan password">
                            <div v-if="submitted && !$v.password.required" class="invalid-feedback">
                                Password is required</div>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-lg btn-block btn-primary mb-3">
                        Masuk Sekarang
                    </button>

                    <!-- Submit -->
                    <!--button type="button" v-on:click="underMaintenace()" class="d-none btn btn-lg btn-block btn-white mb-3">
                        Masuk melalui Google
                    </button>
                    
                    <div class="separator-container text-center mb-3">
                        <div class="separator-title">OR</div>
                    </div-->

                    <button v-on:click="loginGoogle()" type="button" class="btn btn-white btn-lg shadow-sm btn-md btn-block text-center g-recaptcha lift d-none">
                        <div class="d-flex align-items-center justify-content-center w-100">
                            <img src="https://scolacdn.com/images/google-icon.svg" width="16" class="mr-3">
                            <div>Masuk Dengan Google</div>
                        </div>
                    </button>

                    <!-- Signup -->
                    <div class="text-center d-block pb-3 border-bottom">
                        <div class="text-muted text-center">
                            Tidak punya akun? <router-link to="/sign-up">Daftar disini</router-link>
                        </div>
                    </div>

                    <div class="d-block text-center mt-4">
                        <router-link to="/"><span class="fe fe-home mr-2"></span>Kembali ke beranda</router-link>
                    </div>

                    <div class="pt-3 d-none">
                        <div class="d-block font-weight-bold">Menemukan masalah?</div>
                        <div class="d-block">Hubungi tim handal kami <a href="#">disini</a></div>
                    </div>

                    <div class="d-block text-center pt-4">
                        <div class="d-block text-sm text-muted">Scola 2021. All Right Reserved</div>
                    </div>

                </form>

            </div>

            <div class="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">

                <!-- Image -->
                <div class="bg-cover vh-100 mt-n1 mr-n3" v-bind:style="{ 'background-image': 'url(' + school_info.banner_signin + ')' }">
                </div>

            </div>
        </div> <!-- / .row -->
    </div>
</template>
<script>
    import axios from "axios";
    import Vue from 'vue';
    import {required} from "vuelidate/lib/validators";
    import { LoaderPlugin } from 'vue-google-login'
    import { mapState } from 'vuex'

    // google login
    Vue.use(LoaderPlugin, {
    client_id: '773024378042-flaamegoc6nc3imp1lui04sedlq6uv2e.apps.googleusercontent.com',
    scope: 'profile email',
    redirect_uri: window.location.origin
    });

    export default {
        name: 'Signin',
        metaInfo: {
            title: 'SchoolPro',
            titleTemplate: '%s - Signin'
        },
        mounted() {
            this.$store.dispatch('loadSchoolInfo');
        },
        computed: mapState(['school_info']),
        data() {
            return {
                username: "",
                password: "",
                submitted: false
            }
        },
        validations: {
            username: {
                required
            },
            password: {
                required
            }
        },
        methods: {
            handleSubmit() {
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form login',
                    });
                    return;
                } else {
                    this.$http.post(this.$apiconfig + 'auth/signin', {
                            username: this.username,
                            password: this.password
                        })
                        .then(response => {
                            if (response.data.status === "success") {
                                const getUser = response.data.user;
                                const token = getUser.key;
                                if(getUser.roles == 'siswa') {
                                    localStorage.setItem('auth_token', token);
                                    axios.defaults.headers.common['Authorization'] = token;
                                    this.$router.push('calon-siswa/formulir');
                                } else {
                                    this.$swal({
                                        icon: 'error',
                                        title: 'Ups!',
                                        text: "Username atau password tidak ditemukan",
                                    });
                                }
                            } else {
                                this.$swal({
                                    icon: 'error',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                        .catch(function (error) {
                            // Swal.fire('Uh oh!', 'Please check your internet connection.', 'error')
                            console.debug("Login issue: " + error)
                        });
                }
            },
            underMaintenace: function() {
                alert("Fitur sedang dalam maintenance");
            },
            loginGoogle() {
                Vue.GoogleAuth.then(auth2 => {
                    auth2.grantOfflineAccess().then(this.googleSignInCallback);
                });
            },
            googleSignInCallback(authResult) {
                if (authResult['code']) {
                    this.$http.post(this.$apiconfig+'website/auth/oauth2_callback', {
                        provider: 'google',
                        authorization_token: authResult['code']
                    })
                    .then(response => {
                        if (response.data.status === "success") {
                            const getUser = response.data.user;
                            const token = getUser.key;
                            localStorage.setItem('auth_token', token);
                            axios.defaults.headers.common['Authorization'] = token;
                            this.$router.push('calon-siswa/formulir');
                        } else {
                            this.$swal('Uh oh!', response.data.message, 'error')
                            console.debug("Login issue: "+response.data.message)
                        }
                    })
                    .catch(function (error) {
                        this.$swal('Uh oh!', 'Please check your internet connection.', 'error')
                        console.debug("Login issue: "+error)
                    });
                }
            },
        }
    }
</script>